import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactJson from "react-json-view";
import AuthDetails from "./AuthDetails";
import Details from "./Details";
import ExtraDetails from "./ExtraDetails";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_EMPLOYEE_PERMISSIONS } from "constants/actions";
//

const EmployeePermission = ({ employeeId }) => {
  const dispatch = useDispatch();

  const [authDetails, setAuthDetails] = useState([]);
  const [details, setDetails] = useState([]);
  const [extraDetails, setExtraDetails] = useState([]);
  const [accessLevel, setAccessLevel] = useState({});
  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${employeeId}`,
      });
    }
  }, [dispatch, employeeId]);

  const { get_employee_permission } = useSelector(
    (state) => ({
      get_employee_permission: state.employee_permission.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    setAuthDetails(get_employee_permission?.[0]?.auth_details);

    setDetails(get_employee_permission?.[0]?.details);
    if (get_employee_permission?.[0]?.extra_details) {
      const extra_details = Object.entries(
        get_employee_permission?.[0]?.extra_details
      );
      setExtraDetails(extra_details);
    }
    setAccessLevel(get_employee_permission?.[0]?.access_level);
  }, [get_employee_permission]);

  return (
    <>
      <SectionDiv>
        <LabelHead>Auth Details</LabelHead>
        <ReactJson src={authDetails} />
      </SectionDiv>
      <SectionDiv>
        <LabelHead>Details</LabelHead>
        <ReactJson src={details} />
      </SectionDiv>
      <SectionDiv>
        <LabelHead>Extra Details</LabelHead>
        <ReactJson src={extraDetails} />
      </SectionDiv>
      <SectionDiv>
        <LabelHead>Access Level</LabelHead>
        <ReactJson src={accessLevel} />
      </SectionDiv>
    </>
  );
};

export default EmployeePermission;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;
