import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, List, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const RowCard = ({ row }) => {
  const [showList, setShowList] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  function showIpList() {
    setShowList(!showList);
  }

  function showLoginHistory() {
    setShowHistory(!showHistory);
  }

  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Card.Header>
            {row?.first_name} {row?.last_name}
          </Card.Header>
          <Card.Meta>
            {row?.email} {row?.mobile}
          </Card.Meta>
          {/* <Button floated='right'
            onClick={() => navigate(`/app/settings/logins/details/${row?.id}`)}>
            More Details
          </Button> */}
          <Container>
            <List horizontal>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Id</List.Header>
                    {row?.id}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Year</List.Header>
                    {row?.year}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>
                      <PointerSpan onClick={showIpList}>
                        IP's{" "}
                        <Icon
                          disabled
                          name={showList ? "caret up" : "caret down"}
                        />{" "}
                      </PointerSpan>{" "}
                    </List.Header>

                    {showList ? (
                      <IpListView ipList={row?.ip_whitelist} />
                    ) : (
                      "Show details"
                    )}
                  </ListContainer>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>
                      {" "}
                      <PointerSpan onClick={showLoginHistory}>
                        History{" "}
                        <Icon
                          disabled
                          name={showHistory ? "caret up" : "caret down"}
                        />
                      </PointerSpan>{" "}
                    </List.Header>
                    {showHistory ? (
                      <HistoryView history={row?.history} />
                    ) : (
                      "Show details"
                    )}
                  </ListContainer>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content>
                  <ListContainer>
                    <List.Header>Updated</List.Header>
                    {row?.updated_at &&
                      new Date(row?.updated_at).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })}
                  </ListContainer>
                </List.Content>
              </List.Item>
            </List>
          </Container>

          <Card.Description></Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const HistoryView = ({ history }) => {
  console.log(history);
  
  return (
    <div>
      {history &&
        history.map((entry, index) => (
          <RowContainer key={index}>
            <RowItem>
              <strong>IP:</strong> {entry.ip}
            </RowItem>
            <RowItem>
              <strong>Date:</strong>{" "}
              {entry.date &&
                new Date(entry.date).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })}
            </RowItem>
          </RowContainer>
        ))}
    </div>
  );
};

const IpListView = ({ ipList }) => {
  return (
    <div>
      {ipList &&
        ipList.map((entry) => (
          <RowContainer key={entry}>
            <RowItem>
              <strong>IP:</strong> {entry}
            </RowItem>
          </RowContainer>
        ))}
    </div>
  );
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const RowItem = styled.div`
  margin-right: 10px;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;
const PointerSpan = styled.span`
  cursor: pointer;
`;
