import React from "react";
import ReactJson from "react-json-view";
const SignupToken = ({ jsonData }) => {
  return (
    <div>
      {jsonData ? <ReactJson src={jsonData} /> : <p>No signup tokens found</p>}
      <small>(1, "New"),
        (2, "Used"),
        (3, "For Deletion"),
        (9, "Failed"),
        (11, "Resend"),</small>
    </div>
  );
};

export default SignupToken;
