import axios from "axios";
import urlconfig from "WebConfig";
const VERSION = "v1";

// clear cache
export const CLEAR_CACHE = () => () => {
  return axios.get(`${urlconfig.loginapi}/clear-cache/`);
};

// Auth APIS START
export const LOAD_USER = () => () => {
  return axios.get(`${urlconfig.loginapi}/${VERSION}/profile/`, tokenConfig());
};

export const LOGIN_USER = (payload) => {
  const response = axios.post(`${urlconfig.loginapi}/sign-in`, payload, config);
  return response;
};

export async function REGISTER_USER(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/user/sign-up`, data);
}

export async function REGISTER_USER_TOKEN(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/user/token-sign-up`, data);
}

// google auth
export const GOOGLE_AUTH = (payload) => {
  const response = axios.post(
    `${urlconfig.loginapi}/google-auth/sign-in`,
    payload,
    config
  );
  return response;
};

export async function REGISTER_GOOGLE_AUTH(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/google-auth/sign-up`, data);
}

export async function REGISTER_GOOGLE_AUTH_TOKEN(data) {
  // console.log(data)
  return await axios.post(
    `${urlconfig.loginapi}/google-auth/token-sign-up`,
    data
  );
}
// google auth

export async function REGISTER_AMBASSADOR(data) {
  // console.log(data)
  return await axios.post(`${urlconfig.loginapi}/create/ambassador`, data);
}

export const LOGOUT_USER = (payload) => {
  axios.get(`${urlconfig.loginapi}/logout`, payload, tokenConfig());
};

export async function UPDATE_ACCOUNT_DEFAULT(payload) {
  // console.log(data)
  return await axios.post(
    `${urlconfig.loginapi}/change-account-default`,
    payload,
    tokenConfig()
  );
}

export const FORGOT_PASSWORD = async (payload) => {
  const response = await axios.post(
    `${urlconfig.loginapi}/forgot-password`,
    payload,
    config
  );
  // console.log(response)
  let { data } = response;
  // console.log(data)
  return data;
};

export const RESET_PASSWORD = async (payload) => {
  let res = await axios.post(
    `${urlconfig.loginapi}/reset-password`,
    payload,
    config
  );
  return res;
};

//user-password-change/
export const CHANGE_PASSWORD = (payload) =>
  axios.post(`${urlconfig.loginapi}/change-password`, payload, tokenConfig());

export const VALIDATE_EMAIL = (payload) =>
  axios.post(`${urlconfig.loginapi}/validate-email`, payload, config);

export const VALIDATE_TOKEN = (payload) =>
  axios.post(`${urlconfig.loginapi}/validate-signup-token`, payload);

// Headers
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const tokenConfig = () => {
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

const bearerConfig = () => {
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};
// const chatTokenConfig = () => {
//   const chatToken = localStorage.getItem("chat_token");
//   // Headers
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   // If token, add to headers config
//   if (chatToken) {
//     config.headers["Authorization"] = `Bearer ${chatToken}`;
//   }
//   return config;
// };
// Auth APIS END

export const GET_ACCOUNTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/accounts/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_ACCOUNT = ({ id }) =>
  axios.get(`${urlconfig.appapi}/${VERSION}/accounts/${id}/`, tokenConfig());

export const EDIT_ACCOUNT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/accounts/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_ACCOUNT = ({ id }) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/accounts/${id}/`, tokenConfig());

//
//
export const GET_SURVEY_QUESTIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_SURVEY_QUESTION_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/customlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_ALL_SURVEY_QUESTION_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/completelist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_QUESTION = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-question/${id}/`,
    tokenConfig()
  );
export const ADD_SURVEY_QUESTION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-question/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_QUESTION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-question/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_QUESTION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-question/${id}/`,
    tokenConfig()
  );

//

export const GET_EMPLOYEE_SELECT_LIST = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_EMPLOYEE_MANAGER_LIST = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/manager-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};
//
export const GET_EMPLOYEES = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_EMPLOYEE = ({ id }) =>
  axios.get(`${urlconfig.appapi}/${VERSION}/employee/${id}/`, tokenConfig());

export const ADD_EMPLOYEE = (employee) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/`,
    employee,
    tokenConfig()
  );

export const REGISTER_EMPLOYEE = (employee) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee/?add-new=true`,
    employee,
    tokenConfig()
  );

export const EDIT_EMPLOYEE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE = (id) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/employee/${id}/`, tokenConfig());

export const ADD_EMPLOYEE_PIC = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("picture", payload.picture);
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/upload-emp-pic/${payload.id}/`,
    formData,
    tokenConfig()
  );
};
export const SIGNUP_TOKEN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/new-signup-token/`,
    payload,
    tokenConfig()
  );
//
export const GET_EMPLOYEE_CATEGORY_LIST = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-category-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};
//
export const GET_EMPLOYEE_REPORTS = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-report/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_INDUSTRY_BENCHMARK = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/industry-benchmark/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

//
export const GET_ORGANIZATIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_ORGANIZATION_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_ORGANIZATION_STYLE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/selectstylelist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_ORGANIZATION_PRIMARY = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/${id}/primarycategorylist`,
    tokenConfig()
  );

export const FETCH_ORGANIZATION = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/organization/${id}/`,
    tokenConfig()
  );
export const ADD_ORGANIZATION = (organization) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/organization/`,
    organization,
    tokenConfig()
  );

export const EDIT_ORGANIZATION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/organization/${payload.id}/`,
    payload,
    tokenConfig()
  );
//

export const ADD_ORGANIZATION_LOGO = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("logo", payload.logo);
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/upload-org-logo/`,
    formData,
    tokenConfig()
  );
};

export const DELETE_ORGANIZATION = (id) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/organization/${id}/`,
    tokenConfig()
  );

//
export const GET_BLOGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/blogs${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_BLOG = ({ id }) =>
  axios.get(`${urlconfig.appapi}/${VERSION}/blogs/${id}/`, tokenConfig());

export const ADD_BLOG = (blogs) =>
  axios.post(`${urlconfig.appapi}/${VERSION}/blogs/`, blogs, tokenConfig());

export const EDIT_BLOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/blogs/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_BLOG = (id) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/blogs/${id}/`, tokenConfig());

//

export const ADD_BLOG_IMAGE = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("image", payload.image);
  const Uploaded = axios.post(
    `${urlconfig.appapi}/${VERSION}/upload-blog-image/`,
    formData,
    tokenConfig()
  );
  return Uploaded;
};

//
//
export const GET_BLOG_CATEGORIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/blog-category/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_BLOG_CATEGORY = ({ id }) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/blog-category/${id}/`,
    tokenConfig()
  );

export const ADD_BLOG_CATEGORY = (blogs) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/blog-category/`,
    blogs,
    tokenConfig()
  );

export const EDIT_BLOG_CATEGORY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/blog-category/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_BLOG_CATEGORY = (id) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/blog-category/${id}/`,
    tokenConfig()
  );
//
//
export const GET_BLOG_TAGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/blog-tag/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_BLOG_TAG = ({ id }) =>
  axios.get(`${urlconfig.appapi}/${VERSION}/blog-tag/${id}/`, tokenConfig());

export const ADD_BLOG_TAG = (blogs) => {
  // console.log(blogs)
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/blog-tag-update/`,
    blogs,
    tokenConfig()
  );
};

export const EDIT_BLOG_TAG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/blog-tag/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_BLOG_TAG = (id) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/blog-tag/${id}/`, tokenConfig());
//

export const GET_DEPARTMENTS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/department/`, tokenConfig());

export const FETCH_DEPARTMENT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/department/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEPARTMENT = (department) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/department/`,
    department,
    tokenConfig()
  );

export const EDIT_DEPARTMENT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/department/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEPARTMENT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/department/${id}/`,
    tokenConfig()
  );

export const GET_TEAMS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_TEAM = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team/${payload.id}/`,
    tokenConfig()
  );
export const ADD_TEAM = (payload) =>
  axios.post(`${urlconfig.appapi}/${VERSION}/team/`, payload, tokenConfig());

export const EDIT_TEAM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/team/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAM = ({ id }) =>
  axios.delete(`${urlconfig.appapi}/${VERSION}/team/${id}/`, tokenConfig());

// TEAM ACCESS START

export const GET_TEAM_ACCESS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-access/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TEAM_ACCESS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-access/${payload.id}/`,
    tokenConfig()
  );
export const ADD_TEAM_ACCESS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/team-access/`,
    payload,
    tokenConfig()
  );

export const EDIT_TEAM_ACCESS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/team-access/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAM_ACCESS = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/team-access/${id}/`,
    tokenConfig()
  );

// TEAM ACCESS END

// Global Schedule TEAM TYPE
export const GET_TEAM_TYPES = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/team-type/`, tokenConfig());

export const FETCH_TEAM_TYPE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/team-type/${payload.id}/`,
    tokenConfig()
  );

// Global Schedule TEAM TYPE

// employee-role START
export const GET_EMPLOYEE_ROLES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-role/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_ROLE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-role/${payload.id}/`,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_ROLE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-role/${payload.id}/`,
    payload,
    tokenConfig()
  );
// employee role STOP

export const GET_GLOBAL_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GLOBAL_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GLOBAL_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/global-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_GLOBAL_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_GLOBAL_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/global-schedule/${id}/`,
    tokenConfig()
  );
// Global Schedule END

// Global Schedule Frequency START
export const GET_GLOBAL_SCHEDULE_FREQUENCIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/`,
    payload,
    tokenConfig()
  );

export const EDIT_GLOBAL_SCHEDULE_FREQUENCY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_GLOBAL_SCHEDULE_FREQUENCY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/global-schedule-frequency/${id}/`,
    tokenConfig()
  );
// Global Schedule Frequency END

// SURVEY SETTING LIST START
export const GET_SURVEY_SETTING_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_SETTING_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_SETTING_LIST = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_SETTING_LIST = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_SETTING_LIST = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-setting-list/${id}/`,
    tokenConfig()
  );
// SURVEY SETTING LIST END

// teams in survey-compliance START

//
export const GET_ONBOARDINGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_ONBOARDING = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload.id}/`,
    tokenConfig()
  );
export const ADD_ONBOARDING = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/onboarding/`,
    payload,
    tokenConfig()
  );

export const EDIT_ONBOARDING = (payload) => {
  const onbord = axios.patch(
    `${urlconfig.appapi}/${VERSION}/onboarding/${payload.id}/`,
    payload,
    tokenConfig()
  );
  // console.log(onbord)
  return onbord;
};

export const DELETE_ONBOARDING = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/onboarding/${id}/`,
    tokenConfig()
  );
//

export const GET_SURVEY_COMPLIANCES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_COMPLIANCE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_COMPLIANCE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_COMPLIANCE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_COMPLIANCE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-compliance/${id}/`,
    tokenConfig()
  );

// survey delivery survey_delivery

export const GET_SURVEY_DELIVERIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-delivery/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_DELIVERY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-delivery/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_DELIVERY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-delivery/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_DELIVERY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-delivery/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_DELIVERY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-delivery/${id}/`,
    tokenConfig()
  );

// teams in survey-delivery END

// teams in schedule START
export const GET_TEAMS_IN_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TEAMS_IN_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TEAMS_IN_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_TEAMS_IN_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TEAMS_IN_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/teams-in-schedule/${id}/`,
    tokenConfig()
  );
// teams in schedule END

// Leaders In Global Schedule START
export const GET_LEADERS_IN_SCHEDULES = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/`,
    tokenConfig()
  );

export const FETCH_LEADERS_IN_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${payload.id}/`,
    tokenConfig()
  );

export const ADD_LEADERS_IN_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_LEADERS_IN_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_LEADERS_IN_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/leaders-in-schedule/${id}/`,
    tokenConfig()
  );
// Leaders In Global Schedule END

// Leader Survey Participants START
export const GET_LEADER_SURVEY_PARTICIPANTS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/`,
    tokenConfig()
  );

export const FETCH_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${payload.id}/`,
    tokenConfig()
  );

export const ADD_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/`,
    payload,
    tokenConfig()
  );

export const EDIT_LEADER_SURVEY_PARTICIPANT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_LEADER_SURVEY_PARTICIPANT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/leader-survey-participants/${id}/`,
    tokenConfig()
  );
// Leader Survey Participants END

// Personality Teams START
export const GET_PERSONALITY_TEAMS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_TEAM = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PERSONALITY_TEAM = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/`,
    payload,
    tokenConfig()
  );

export const EDIT_PERSONALITY_TEAM = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PERSONALITY_TEAM = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/teams-in-personality/${id}/`,
    tokenConfig()
  );
// Personality Teams END

// Personality Participants START
export const GET_PERSONALITY_PARTICIPANTS = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_PARTICIPANT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PERSONALITY_PARTICIPANT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/`,
    payload,
    tokenConfig()
  );

export const EDIT_PERSONALITY_PARTICIPANT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PERSONALITY_PARTICIPANT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/participants-in-personality/${id}/`,
    tokenConfig()
  );
// Personality Participants ENDCANCEL_SUBSCRIPTION

// PAYMENT_METHODS START
export const GET_STRIPE_TAX_RATES = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/tax-rates/`, tokenConfig());

// PAYMENT_METHODS START
export const GET_PAYMENT_METHODS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/payment-method/`, tokenConfig());

export const FETCH_PAYMENT_METHOD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/payment-method/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PAYMENT_METHOD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/payment-method/`,
    payload,
    tokenConfig()
  );

export const EDIT_PAYMENT_METHODS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/payment-method/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PAYMENT_METHOD = ({ id }) => {
  return axios.delete(
    `${urlconfig.appapi}/${VERSION}/payment-method/${id}/`,
    tokenConfig()
  );
};

// Ambassador START

export const GET_AMBASSADORS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/ambassador/`, tokenConfig());

export const FETCH_AMBASSADOR = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/ambassador/${payload.id}/`,
    tokenConfig()
  );

export const ADD_AMBASSADOR = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/ambassador/`,
    payload,
    tokenConfig()
  );

export const ADD_AMBASSADOR_PIC = (payload) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("picture", payload.picture);
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/upload-ambassador-pic/${payload.id}/`,
    formData,
    tokenConfig()
  );
};

export const EDIT_AMBASSADOR = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/ambassador/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_AMBASSADOR = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/ambassador/${id}/`,
    tokenConfig()
  );

export const VALIDATE_AMBASSADOR = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-ambassador/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
// Ambassador END

// File Upload Start

export const GET_FILE_UPLOADS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/file-upload/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_FILE_UPLOAD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/file-upload/${payload.id}/`,
    tokenConfig()
  );

// export const ADD_FILE_UPLOAD = (payload) =>
//   axios.post(
//     `${urlconfig.appapi}/${VERSION}/file-upload/`,
//     payload,
//     tokenConfig()
//   );

export const ADD_FILE_UPLOAD = (payload) => {
  // console.log(payload)
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("organization", payload.organization);
  formData.append("data_file", payload.data_file);
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/file-upload/`,
    formData,
    tokenConfig()
  );
};

export const EDIT_FILE_UPLOAD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/file-upload/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_FILE_UPLOAD = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/file-upload/${id}/`,
    tokenConfig()
  );

// file Upload End
// survey-structure

// survey-structure Start
export const GET_SURVEY_STRUCTURES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_SURVEY_STRUCTURE_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
export const COPY_SURVEY_STRUCTURE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/duplicate-survey-structure/`,
    payload,
    tokenConfig()
  );

export const FETCH_SURVEY_STRUCTURE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_STRUCTURE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-structure/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STRUCTURE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const CSV_FILE_SURVEY_STRUCTURE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-structure/download-csv/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STRUCTURE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-structure/${id}/`,
    tokenConfig()
  );

// survey-structure End

// survey-structure-compliance Start
export const GET_SURVEY_STRUCTURE_COMPLIANCES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_SURVEY_STRUCTURE_COMPLIANCE_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
export const COPY_SURVEY_STRUCTURE_COMPLIANCE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/duplicate-survey-structure-compliance/`,
    payload,
    tokenConfig()
  );

export const FETCH_SURVEY_STRUCTURE_COMPLIANCE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_STRUCTURE_COMPLIANCE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STRUCTURE_COMPLIANCE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STRUCTURE_COMPLIANCE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-structure-compliance/${id}/`,
    tokenConfig()
  );

// survey-structure-compliance End

// employee-category Start
export const GET_EMPLOYEE_CATEGORIES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-category/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_CATEGORY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-category/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_CATEGORY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-category/`,
    payload,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_CATEGORY = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-category/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_EMPLOYEE_CATEGORY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-category/${id}/`,
    tokenConfig()
  );

// employee-category End

// employee-permission Start
export const GET_EMPLOYEE_PERMISSIONS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_PERMISSION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_PERMISSION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-permission/`,
    payload,
    tokenConfig()
  );

export const EDIT_EMPLOYEE_PERMISSION = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_EMPLOYEE_PERMISSION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-permission/${id}/`,
    tokenConfig()
  );

// employee-permission End

// GET_WEB_API_CREDENTIALS Start
export const GET_WEB_API_CREDENTIALS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_WEB_API_CRED_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_WEB_API_CREDENTIAL = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/${payload.id}/`,
    tokenConfig()
  );

export const ADD_WEB_API_CREDENTIAL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/`,
    payload,
    tokenConfig()
  );

export const EDIT_WEB_API_CREDENTIAL = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_WEB_API_CREDENTIAL = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/web-api-credential/${id}/`,
    tokenConfig()
  );

// GET_WEB_API_CREDENTIALS End

// GET_WEB_API_LOGS Start
export const GET_WEB_API_LOGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-api-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_WEB_API_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-api-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_WEB_API_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/web-api-log/`,
    payload,
    tokenConfig()
  );

export const EDIT_WEB_API_LOG = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/web-api-log/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_WEB_API_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/web-api-log/${id}/`,
    tokenConfig()
  );

// GET_WEB_API_LOGS End

// GET_APP_LIST_URLS Start
export const GET_APP_LIST_URLS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-list-url/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_APP_LIST_URL = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-list-url/${payload.id}/`,
    tokenConfig()
  );

export const ADD_APP_LIST_URL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/app-list-url/`,
    payload,
    tokenConfig()
  );

export const EDIT_APP_LIST_URL = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/app-list-url/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_APP_LIST_URL = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/app-list-url/${id}/`,
    tokenConfig()
  );

// GET_APP_LIST_URLS End

// GET_APP_SERVERS Start

export const GET_APP_SERVERS_MAKE_COMMANDS = (payload) =>
  axios.get(`${urlconfig.devopsApi}/make-commands`, bearerConfig());

export const POST_APP_SERVERS_MAKE_BUILD = (payload) =>
  axios.post(
    `${urlconfig.devopsApi}/app-server-log/new-app-server-log`,
    payload,
    bearerConfig()
  );

export const GET_APP_SERVERS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-server/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_APP_SERVER = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-server/${payload.id}/`,
    tokenConfig()
  );

export const ADD_APP_SERVER = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/app-server/`,
    payload,
    tokenConfig()
  );

export const EDIT_APP_SERVER = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/app-server/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_APP_SERVER = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/app-server/${id}/`,
    tokenConfig()
  );

// GET_APP_SERVERS End

// GET_APP_SERVER_LOGS Start
export const GET_APP_SERVER_LOGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-server-update-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_APP_SERVER_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/app-server-update-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_APP_SERVER_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/app-server-update-log/`,
    payload,
    tokenConfig()
  );

export const EDIT_APP_SERVER_LOG = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/app-server-update-log/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );
};

export const DELETE_APP_SERVER_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/app-server-update-log/${id}/`,
    tokenConfig()
  );

// GET_APP_SERVER_LOGS End

// STRIPE PAYMENT API
//

export const GET_NEXT_INVOICE = () =>
  axios.get(`${urlconfig.payment}/${VERSION}/next-invoice/`, tokenConfig());
// p
export const GET_STRIPE_PAYMENT_METHODS = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/stripe-payment-methods/`,
    payload,
    tokenConfig()
  );

export const CANCEL_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/cancel-subscription/`,
    payload,
    tokenConfig()
  );

export const UPDATE_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const UPGRADE_PLAN = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const DOWNGRADE_PLAN = (payload) =>
  axios.post(
    `${urlconfig.payment}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );
// STRIPE PAYMENT API
// SUBSCRIPTION START
export const GET_SUBSCRIPTIONS = () =>
  axios.get(`${urlconfig.appapi}/${VERSION}/subscription/`, tokenConfig());

export const FETCH_SUBSCRIPTION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/subscription/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SUBSCRIPTION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/subscription/`,
    payload,
    tokenConfig()
  );

export const EDIT_SUBSCRIPTION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/subscription/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SUBSCRIPTION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/subscription/${id}/`,
    tokenConfig()
  );
// SUBSCRIPTION END

// debrief schedule START
export const GET_DEBRIEF_SCHEDULES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_DEBRIEF_SCHEDULES_SELECT_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/excel-summary/`,
    payload,
    tokenConfig()
  );
//
export const FETCH_DEBRIEF_SCHEDULE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${payload.id}/`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_SCHEDULE_SUMMARY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${payload.id}/summary/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/`,
    payload,
    tokenConfig()
  );

export const MERGE_DEBRIEF_SCHEDULE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/merge-debrief-schedule/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_SCHEDULE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_SCHEDULE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-schedule/${id}/`,
    tokenConfig()
  );
// debrief schedule END

// debrief summary START
export const GET_DEBRIEF_SUMMARY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_DEBRIEF_SUMMARY_NEXT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/get-next/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_SUMMARY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_SUMMARY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_SUMMARY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/${payload.id}/`,
    payload,
    tokenConfig()
  );
//

export const DELETE_DEBRIEF_SUMMARY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-summary/${id}/`,
    tokenConfig()
  );

// debrief summary END

// debrief analytics START
export const GET_DEBRIEF_ANALYTICS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-analytics/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_ANALYTICS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-analytics/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_ANALYTICS = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-analytics/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_ANALYTICS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-analytics/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_ANALYTICS = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-analytics/${id}/`,
    tokenConfig()
  );
// debrief analytics END

// debrief response START
export const GET_DEBRIEF_RESPONSES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_RESPONSE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_RESPONSE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-response/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_RESPONSE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${payload.id}/`,
    payload,
    tokenConfig()
  );

// UPDATE_DEBRIEF_RESPONSE
export const UPDATE_DEBRIEF_RESPONSE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/update-debrief-response/`,
    payload,
    tokenConfig()
  );

export const DELETE_DEBRIEF_RESPONSE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-response/${id}/`,
    tokenConfig()
  );
// debrief response END

// debrief token START
export const GENERATE_DEBRIEF_TOKEN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/generate-brief-tokens/`,
    payload,
    tokenConfig()
  );

export const SEND_DEBRIEF_TOKEN_EMAIL = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/send-brief-token-email/`,
    payload,
    tokenConfig()
  );

export const GET_DEBRIEF_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/brief-token/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_DEBRIEF_TOKEN = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/brief-token/${payload.id}/`,
    tokenConfig()
  );

export const ADD_DEBRIEF_TOKEN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/brief-token/`,
    payload,
    tokenConfig()
  );

export const EDIT_DEBRIEF_TOKEN = (payload) => {
  return axios.patch(
    `${urlconfig.appapi}/${VERSION}/brief-token/${payload.id}/`,
    payload,
    tokenConfig()
  );
};

export const DELETE_DEBRIEF_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/brief-token/${id}/`,
    tokenConfig()
  );
// debrief token END

// email template START
export const GET_EMAIL_TEMPLATES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/email-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_EMAIL_TEMPLATE_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/email-template/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
// duplicate-email-template
export const COPY_EMAIL_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/duplicate-email-template/`,
    payload,
    tokenConfig()
  );

export const FETCH_EMAIL_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/email-template/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMAIL_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/email-template/`,
    payload,
    tokenConfig()
  );

export const EDIT_EMAIL_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/email-template/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMAIL_TEMPLATE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/email-template/${id}/`,
    tokenConfig()
  );
// email template END

// pdf template START
export const GET_PDF_TEMPLATES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_PDF_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${payload.id}/`,
    tokenConfig()
  );

export const ADD_PDF_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/pdf-template/`,
    payload,
    tokenConfig()
  );

export const EDIT_PDF_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_PDF_TEMPLATE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/pdf-template/${id}/`,
    tokenConfig()
  );
// email template END

// Survey Reports
export const GET_PERSONALITY_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/personality/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_PERSONALITY_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/personality/${payload.id}/`,
    tokenConfig()
  );

export const GET_CULTURE_REPORTS = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/culture/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_CULTURE_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/culture/${payload.id}/`,
    tokenConfig()
  );

export const UPDATE_CULTURE_COMMENTS = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/add-culture-comments/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const GET_MOOD_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_MOOD_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood/${payload.id}/`,
    tokenConfig()
  );

//
export const GET_CULTURE_ORG_REPORTS = (payload) => {
  // console.log(payload)
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/culture-org/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const FETCH_CULTURE_ORG_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/culture-org/${payload.id}/`,
    tokenConfig()
  );

//

export const GET_MOOD_ORG_REPORTS = (payload) => {
  // console.log(payload)
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/mood-org/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
};

export const FETCH_MOOD_ORG_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/mood-org/${payload.id}/`,
    tokenConfig()
  );

//

export const GET_LEADERSHIP_REPORTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leadership/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_LEADERSHIP_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/leadership/${payload.id}/`,
    tokenConfig()
  );

//
export const CREATE_PAYMENT_INTENT = (payload) => {
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/create-payment-intent/`,
    payload,
    tokenConfig()
  );
};

// price
export const GET_PRICES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/price/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );

export const FETCH_PRICE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/price/${payload.id}/`,
    tokenConfig()
  );
// price

export const VALIDATE_EMPLOYEE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-employee/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const VALIDATE_ORGANIZATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-organization/${
      payload ? `?${payload}` : ""
    }`
  );

// Survey Reports validate-token/

export const PRE_VALIDATE_EMAIL = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/validate-pre-login/${
      payload ? `?token=${payload.token}` : ""
    }`
  );
};

// sign up token START
export const GET_SIGNUP_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/signup-token/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
export const DELETE_SIGNUP_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/signup-token/${id}/`,
    tokenConfig()
  );
// sign up token END
// survey token START
export const GET_SURVEY_TOKENS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-token/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
export const DELETE_SURVEY_TOKEN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-token/${id}/`,
    tokenConfig()
  );
// survey token END
// Email START
export const GET_EMAILS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/inbound-email/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
export const FETCH_EMAIL = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/inbound-email/${payload.id}/`,
    tokenConfig()
  );

export const DELETE_EMAIL = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/inbound-email/${id}/`,
    tokenConfig()
  );
// Email END
// Email START
export const GET_WEB_IMAGES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-image/${payload ? `?${payload}` : ""}`,
    tokenConfig()
  );
export const FETCH_WEB_IMAGE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/web-image/${payload.id}/`,
    tokenConfig()
  );
export const DELETE_WEB_IMAGE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/web-image/${id}/`,
    tokenConfig()
  );

export const EDIT_WEB_IMAGE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/web-image/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const ADD_WEB_IMAGE = (payload) => {
  const formData = new FormData();
  formData.append("image", payload.image);
  const Uploaded = axios.post(
    `${urlconfig.appapi}/${VERSION}/web-image/`,
    formData,
    tokenConfig()
  );
  return Uploaded;
};

// Email END
// survey token START
export const GET_SURVEY_RESPONSES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const DELETE_SURVEY_RESPONSE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-response/${id}/`,
    tokenConfig()
  );
// survey token END
// GET_EMAIL_DELIVERY_STATUS START
export const GET_EMAIL_DELIVERY_STATUS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/email-delivery-status/${
      payload ? payload : ""
    }`,
    tokenConfig()
  );
//  GET_EMAIL_DELIVERY_STATUS END

export const GET_PAYMENTS = () => {
  return;
};

export const FETCH_PAYMENT = () => {
  return;
};

export const ADD_PAYMENT = () => {
  return;
};
export const EDIT_PAYMENT = () => {
  return;
};

export const DELETE_PAYMENT = () => {
  return;
};

// Employee Record
export const GET_EMPLOYEE_RECORD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record/?${payload}`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_RECORD = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload.id}/`,
    tokenConfig()
  );
};

export const ADD_EMPLOYEE_RECORD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_RECORD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record/${payload}/`,
    tokenConfig()
  );

// Employee Record

// Employee Log
export const GET_EMPLOYEE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-log/?${payload}`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-log/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_LOG = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-log/${payload}/`,
    tokenConfig()
  );

// Employee Log

// Employee employee-record-list
export const GET_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/?${payload}`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_RECORD_LIST = (payload) => {
  return axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/`,
    payload,
    tokenConfig()
  );
};

export const UPDATE_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD_LIST = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record-list/${payload}/`,
    tokenConfig()
  );

// Employee employee-record-list

// Employee employee-record-value
export const GET_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/?${payload}`,
    tokenConfig()
  );

export const FETCH_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload.id}/`,
    tokenConfig()
  );

export const ADD_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/`,
    payload,
    tokenConfig()
  );

export const UPDATE_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_EMPLOYEE_RECORD_VALUE = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/employee-record-value/${payload}/`,
    tokenConfig()
  );

// Employee employee-record-value
// survey standard START

export const CLEAR_SERVER_CACHE = (payload) =>
  axios.get(`${urlconfig.clearCache}`);

export const GET_RESET_SERVER_CACHE = (payload) =>
  axios.get(
    `${urlconfig.portalapi}/${VERSION}/survey-standard/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_APP_SURVEY_STANDARDS = (payload) =>
  axios.get(
    `${urlconfig.portalapi}/${VERSION}/survey-standard/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_SURVEY_STANDARDS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_STANDARD = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_STANDARD = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-standard/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STANDARD = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STANDARD = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-standard/${id}/`,
    tokenConfig()
  );
// survey standard END
// survey standard log START
export const GET_SURVEY_STANDARD_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SURVEY_STANDARD_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-standard-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SURVEY_STANDARD_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/survey-standard-log/`,
    payload,
    tokenConfig()
  );

export const EDIT_SURVEY_STANDARD_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/survey-standard-log/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_SURVEY_STANDARD_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/survey-standard-log/${id}/`,
    tokenConfig()
  );
// survey standard log END

export const GET_CHAT_GPT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const POST_CHAT_GPT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-response/`,
    payload,
    tokenConfig()
  );
//

//

export const GET_ADMIN_DEFAULTS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/admin-default/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_ADMIN_DEFAULT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/admin-default/${payload.id}/`,
    tokenConfig()
  );

export const ADD_ADMIN_DEFAULT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/admin-default/`,
    payload,
    tokenConfig()
  );

export const EDIT_ADMIN_DEFAULT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/admin-default/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_ADMIN_DEFAULT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/admin-default/${id}/`,
    tokenConfig()
  );
//
//
export const GET_GPT_SUMMARY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-summary/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_SUMMARY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-summary/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_SUMMARY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-summary/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_SUMMARY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-summary/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_SUMMARY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-summary/${id}/`,
    tokenConfig()
  );
//
//
export const GET_GPT_MODEL_MAINS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_GPT_MODEL_MAIN_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_MODEL_MAIN = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_MODEL_MAIN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_MODEL_MAIN = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_MODEL_MAIN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/gpt-model-main/${id}/`,
    tokenConfig()
  );

//
export const GET_GPT_RESPONSE_MAINS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-response-main/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_RESPONSE_MAIN = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-response-main/${payload.id}/`,
    tokenConfig()
  );
//
//
export const GET_CHAT_GPT_WEB_CHATS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-webchat/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_CHAT_GPT_WEB_CHAT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chatgpt-webchat/${payload.id}/`,
    tokenConfig()
  );

export const ADD_CHAT_GPT_WEB_CHAT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chatgpt-webchat/`,
    payload,
    tokenConfig()
  );

export const EDIT_CHAT_GPT_WEB_CHAT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/chatgpt-webchat/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_CHAT_GPT_WEB_CHAT = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/chatgpt-webchat/${id}/`,
    tokenConfig()
  );
//
// this bulk action for ai brief summary
export const BRIEF_AI_SUMMARY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-ai-summary/`,
    payload,
    tokenConfig()
  );

export const ADD_GPT_RESPONSE_MAIN = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/gpt-response-main/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_RESPONSE_MAIN = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/gpt-response-main/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_RESPONSE_MAIN = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/gpt-response-main/${id}/`,
    tokenConfig()
  );

//
export const GET_GPT_RESPONSE_SCORES = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-response-score/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_RESPONSE_SCORE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-response-score/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_RESPONSE_SCORE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/gpt-response-score/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_RESPONSE_SCORE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/gpt-response-score/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_RESPONSE_SCORE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/gpt-response-score/${id}/`,
    tokenConfig()
  );
//
export const GET_GPT_MEMBER_SELECT = (payload) => {
  return axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-members/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
};

export const GET_GPT_MEMBERS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-members/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_MEMBER = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/gpt-members/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_MEMBER = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/gpt-members/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_MEMBER = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/gpt-members/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_MEMBER = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/gpt-members/${id}/`,
    tokenConfig()
  );
//
//
//  export const GET_GOOGLE_DEMO_SHEET = (payload) =>
//   axios.get(
//     `${urlconfig.appapi}/${VERSION}/demo-gsheet-responses/`,
//     payload,
//     tokenConfig()
//   );
//
export const POST_GOOGLE_DEMO_SHEET = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/demo-gsheet-responses/`,
    payload,
    tokenConfig()
  );
//
//
export const GET_MARKETING_ROIS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/marketing-roi/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_MARKETING_ROI = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/marketing-roi/${payload.id}/`,
    tokenConfig()
  );

export const ADD_MARKETING_ROI = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/marketing-roi/`,
    payload,
    tokenConfig()
  );

export const EDIT_MARKETING_ROI = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/marketing-roi/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_MARKETING_ROI = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/marketing-roi/${id}/`,
    tokenConfig()
  );

//
// activity-log
export const GET_ACTIVITY_LOGS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/activity-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_ACTIVITY_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/activity-log/${payload.id}/`,
    tokenConfig()
  );
// // /<str:task_id>/
export const FETCH_ACTIVITY_LOG_STATUS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/check-activity-status/${payload.id}/`,
    tokenConfig()
  );
export const ADD_ACTIVITY_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/activity-log/`,
    payload,
    tokenConfig()
  );

export const EDIT_ACTIVITY_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/activity-log/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_ACTIVITY_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/activity-log/${id}/`,
    tokenConfig()
  );

//
export const GET_GPT_RECOMMENDATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_RECOMMENDATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_RECOMMENDATION = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_RECOMMENDATION = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_RECOMMENDATION = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation/${id}/`,
    tokenConfig()
  );
//
//
// chat-gpt-recommendation-log
export const GET_GPT_RECOMMENDATION_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation-log/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_GPT_RECOMMENDATION_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation-log/${payload.id}/`,
    tokenConfig()
  );

export const ADD_GPT_RECOMMENDATION_LOG = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation-log/`,
    payload,
    tokenConfig()
  );

export const EDIT_GPT_RECOMMENDATION_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation-log/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_GPT_RECOMMENDATION_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/chat-gpt-recommendation-log/${id}/`,
    tokenConfig()
  );
//
// GET_RECOMMENDATION_MODEL_RATING
//
export const GET_RECOMMENDATION_MODEL_RATING = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-model-rating/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_RECOMMENDATION_MODEL_RATING = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-model-rating/${payload.id}/`,
    tokenConfig()
  );

export const ADD_RECOMMENDATION_MODEL_RATING = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/recommendation-model-rating/`,
    payload,
    tokenConfig()
  );

export const EDIT_RECOMMENDATION_MODEL_RATING = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/recommendation-model-rating/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_RECOMMENDATION_MODEL_RATING = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/recommendation-model-rating/${id}/`,
    tokenConfig()
  );
//
// GET_SCHEDULE_TEMPLATE START
//
export const GET_SCHEDULE_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_SCHEDULE_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${payload.id}/`,
    tokenConfig()
  );

export const ADD_SCHEDULE_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/schedule-template/`,
    payload,
    tokenConfig()
  );

export const EDIT_SCHEDULE_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_SCHEDULE_TEMPLATE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/schedule-template/${id}/`,
    tokenConfig()
  );

// GET_SCHEDULE_TEMPLATE END
//
export const GET_DOCUMENTATION = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/documentation/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

//
// GET_RECOMMENDATION_MODELS

export const GET_RECOMMENDATION_MODELS = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-models/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

//
// GET_REPORT_TEMPLATE
export const GET_REPORT_TEMPLATE_SELECT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/selectlist/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const GET_REPORT_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_REPORT_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/${payload.id}/`,
    tokenConfig()
  );

export const ADD_REPORT_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/`,
    payload,
    tokenConfig()
  );

export const EDIT_REPORT_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_REPORT_TEMPLATE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/recommendation-template/${id}/`,
    tokenConfig()
  );
//

// GET_REPORT_TEMPLATE
//
export const GET_LOGIN_HISTORY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/login-history/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_LOGIN_HISTORY = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/login-history/${payload.id}/`,
    tokenConfig()
  );

export const ADD_LOGIN_HISTORY = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/login-history/`,
    payload,
    tokenConfig()
  );

export const EDIT_LOGIN_HISTORY = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/login-history/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_LOGIN_HISTORY = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/login-history/${id}/`,
    tokenConfig()
  );
//
// Change Log / CHANGE_LOG
//
export const GET_CHANGE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/change-log-data/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_CHANGE_LOG = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/change-log-data/${payload.id}/`,
    tokenConfig()
  );

export const ADD_CHANGE_LOG = (payload) => {
  console.log(payload);
  axios.post(
    `${urlconfig.appapi}/${VERSION}/change-log-data/`,
    payload,
    tokenConfig()
  );
};

export const EDIT_CHANGE_LOG = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/change-log-data/${payload.id}/${
      payload.query ? `?${payload.query}` : ""
    }`,
    payload,
    tokenConfig()
  );

export const DELETE_CHANGE_LOG = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/change-log-data/${id}/`,
    tokenConfig()
  );
//

// / GET REACT PDF REPORTS TEMPLATES
export const GET_DEBRIEF_PDF_REPORT_TEMPLATES = () =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/templatelist/`,
    tokenConfig()
  );

//  // re_generate_pdf
export const REGENERATE_DEBRIEF_PDF_REPORT = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/re_generate_pdf/`,
    payload,
    tokenConfig()
  );

// // secure_aws_download_url
export const GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/secure_aws_download_url/`,
    payload,
    tokenConfig()
  );

//

// GET REACT PDF REPORTS
export const GET_DEBRIEF_PDF_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

// // CREATE REACT PDF REPORTS
// export const CREATE_DEBRIEF_PDF_REPORT = (payload) =>
//   axios.post(
//     `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/`,
//     payload,
//     tokenConfig()
//   );

// FETCH REACT PDF REPORTS
export const FETCH_DEBRIEF_PDF_REPORT = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload.id}/`,
    tokenConfig()
  );

// UPDATE REACT PDF REPORTS
export const UPDATE_DEBRIEF_PDF_REPORT = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload.id}/`,
    payload,
    tokenConfig()
  );

// DELETE REACT PDF REPORTS
export const DELETE_DEBRIEF_PDF_REPORT = (payload) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/debrief-pdf-report/${payload}/`,
    tokenConfig()
  );
//

const chatTokenConfig = () => {
  const chatToken = localStorage.getItem("chat_token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (chatToken) {
    config.headers["Authorization"] = `Bearer ${chatToken}`;
  }
  return config;
};

export const POST_GPT_PROCESS = (payload) =>
  axios.post(
    `${urlconfig.aiProcessUrl}/ai-chat-response-retake`,
    payload,
    chatTokenConfig()
  );

export const GET_SURVEY_AI_LOG = (payload) => {
  const apiResponse = axios.get(
    `${urlconfig.appapi}/${VERSION}/survey-ai-chat-logs/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );
  return apiResponse;
};

// task template, task_template START
export const GET_TASK_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TASK_TEMPLATE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TASK_TEMPLATE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template/`,
    payload,
    tokenConfig()
  );

export const EDIT_TASK_TEMPLATE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TASK_TEMPLATE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/task-template/${id}/`,
    tokenConfig()
  );
// task template, task_template  END

// task template user tasks, task_template-user-tasks task-template-user-tasks START
export const GET_TASK_TEMPLATE_USER_TASK = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TASK_TEMPLATE_USER_TASK = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TASK_TEMPLATE_USER_TASK = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/`,
    payload,
    tokenConfig()
  );

export const EDIT_TASK_TEMPLATE_USER_TASK = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TASK_TEMPLATE_USER_TASK = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/task-template-user-tasks/${id}/`,
    tokenConfig()
  );
// task template user tasks, task_template-user-tasks task-template-user-tasks END

// task template user response, task_template-user-response task-template-user-response START
export const GET_TASK_TEMPLATE_USER_RESPONSE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TASK_TEMPLATE_USER_RESPONSE = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TASK_TEMPLATE_USER_RESPONSE = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/`,
    payload,
    tokenConfig()
  );

export const EDIT_TASK_TEMPLATE_USER_RESPONSE = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TASK_TEMPLATE_USER_RESPONSE = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/task-template-user-response/${id}/`,
    tokenConfig()
  );
// task template user response, task_template-user-response task-template-user-response END

// task template user response, task_template-user-response task-template-user-response START
export const GET_TASK_TEMPLATE_USER_REQUEST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-request/${
      payload ? `?${payload}` : ""
    }`,
    tokenConfig()
  );

export const FETCH_TASK_TEMPLATE_USER_REQUEST = (payload) =>
  axios.get(
    `${urlconfig.appapi}/${VERSION}/task-template-user-request/${payload.id}/`,
    tokenConfig()
  );

export const ADD_TASK_TEMPLATE_USER_REQUEST = (payload) =>
  axios.post(
    `${urlconfig.appapi}/${VERSION}/task-template-user-request/`,
    payload,
    tokenConfig()
  );

export const EDIT_TASK_TEMPLATE_USER_REQUEST = (payload) =>
  axios.patch(
    `${urlconfig.appapi}/${VERSION}/task-template-user-request/${payload.id}/`,
    payload,
    tokenConfig()
  );

export const DELETE_TASK_TEMPLATE_USER_REQUEST = ({ id }) =>
  axios.delete(
    `${urlconfig.appapi}/${VERSION}/task-template-user-request/${id}/`,
    tokenConfig()
  );
// task template user response, task_template-user-response task-template-user-response END
export const GET_REPORT_SERVER_TEST = () =>
  axios.get(`${urlconfig.reportApi}/report/test`, bearerConfig());


export const GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE = ({ employee_id }) =>
  axios.get(
    `${urlconfig.reportApi}/report/cache/clear/${employee_id}/
    }`,
    bearerConfig()
  );

export const GET_REPORT_SERVER_RESET_CACHE = () =>
  axios.get(
    `${urlconfig.reportApi}/report/cache/clear-all
      }`,
    bearerConfig()
  );
