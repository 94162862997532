import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { Card, Button, List } from "semantic-ui-react";

import MergeSurvey from "./MergeSurvey";

const RowCard = ({
  row,
  get_organization_select,
  get_survey_question_select,
  mergeSurvey,
  setMergeSurvey,
}) => {
  const navigate = useNavigate();
  const [briefQuestion, setBriefQuestion] = useState(false);

  useEffect(() => {
    if (row?.brief_question) {
      setBriefQuestion(
        get_survey_question_select?.filter(
          (qu) => qu.id === row?.brief_question
        )?.[0]
      );
    }
  }, [row, get_survey_question_select]);
  console.log(row?.status);
  return (
    <>
      <Card fluid key={row?.id}>
        <Card.Content>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>Org</HeaderDiv>
                <ContentDiv>
                  {row?.organization} #
                  {row?.organization &&
                    get_organization_select.filter(
                      (org) => org.id === row?.organization
                    )?.[0]?.name}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Type</HeaderDiv>
                <ContentDiv>{row?.type_of_text}</ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Status</HeaderDiv>
                <ContentDiv>
                  <ChangeColor status={row?.status}>
                    {row?.status_text}
                  </ChangeColor>
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Time Zone</HeaderDiv>
                <ContentDiv>{row?.time_zone}</ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Question</HeaderDiv>
                <ContentDiv>
                  {row?.brief_question?.name} ({row?.brief_question?.sort_order}
                  ){row?.brief_question?.status_text}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Name</HeaderDiv>
                <ContentDiv>{row?.name}</ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>

                <ActiveSurvey status={row?.include_members}>
                  Survey ID {row?.include_members? "sharable": "non-sharable"}
                  </ActiveSurvey>
                </HeaderDiv>
                <ContentDiv>
                  <ActiveSurvey status={row?.include_members}>{row?.survey_id}</ActiveSurvey>
                </ContentDiv>
              </ListContainer>
            </RowData>
          </Container>
          <Container>
            <ListContainer>
              <List divided horizontal>
                <List.Item>
                  <HeaderDiv>Secondary</HeaderDiv>
                </List.Item>
                {row?.secondary ? (
                  row.secondary.map((s) => {
                    return <List.Item key={s}>{s}</List.Item>;
                  })
                ) : (
                  <List.Item>All Secondary included</List.Item>
                )}
              </List>
            </ListContainer>
          </Container>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>#ID</HeaderDiv>
                <ContentDiv>{row?.id}</ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Created Date</HeaderDiv>
                <ContentDiv>
                  {row?.created_at &&
                    format(new Date(row?.created_at), "dd-MMM-yyyy hh:mm a")}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Scheduled Date</HeaderDiv>
                <ContentDiv>
                  {row?.scheduled_for &&
                    format(new Date(row?.scheduled_for), "dd-MMM-yyyy hh:mm a")}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Close Date</HeaderDiv>
                <ContentDiv>
                  {row?.close_at &&
                    format(new Date(row?.close_at), "dd-MMM-yyyy hh:mm a")}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Employees</HeaderDiv>
                <ContentDiv>{row?.total_requests}</ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Responded</HeaderDiv>
                <ContentDiv>{row?.total_responses}</ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Name UID</HeaderDiv>
                <ContentDiv>{row?.name_uid}</ContentDiv>
              </ListContainer>
            </RowData>
          </Container>
          <Card.Description>
            <MergeSurvey
              debriefSchedule={row}
              setMergeSurvey={setMergeSurvey}
              mergeSurvey={mergeSurvey}
            />

            <Button
              floated="right"
              onClick={() => navigate(`/app/brief-schedule/details/${row?.id}`)}
            >
              More Details
            </Button>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

const ChangeColor = styled.div`
  padding: 5px;
  color: white;
  background-color: ${(props) => {
    if (props.status < 3) {
      return "orange";
    } else if (props.status < 11) {
      return "green";
    } else if (props.status === 21) {
      return "violet";
    } else {
      return "default-color";
    }
  }};
`;

const ActiveSurvey = styled.span`
  padding: 5px;
  color: white;
  background-color: ${(props) => {
    if (props.status === true) {
      return "green";
    } else {
      return "gray";
    }
  }};
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;

const RowData = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`;

const ContentDiv = styled.div`
  // background-color: #b6701e;
`;
