import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";
import { CustomMessages, CustomMessagesList } from "Messages/Errors/Message"

import {
    CLEAR_ERRORS,
  } from "constants/actions";

export const BasicErrorMessage = () => {
    const dispatch = useDispatch();

  const get_errors = useSelector(
    (state) => state.errors,
    shallowEqual
  );
  const [submitErrors, setSubmitErrors] = useState([])

  useEffect(() => {
    if (get_errors?.errors?.request?.response) {
      let RESP = JSON.parse(get_errors?.errors?.request?.response)

      let errors = Object.values(RESP).map((message) => {
        if (CustomMessagesList.hasOwnProperty(message)) {
          return { message, isError: CustomMessagesList[message].isError };
        } else {
          return { message, isError: true }; // Default to error if message not found in CustomMessagesList
        }
      });
      setSubmitErrors(errors);
    }
  }, [get_errors]);


  function ReLoadPAge() {
    // window.location.reload();
    dispatch({
        type: CLEAR_ERRORS,
      });
      setSubmitErrors([])
  }


  if (submitErrors?.length > 0) {
    return (
      submitErrors.map(({ message, isError }, i) => ( 
      <Message onDismiss={ReLoadPAge} color={isError? "red": "blue"} key={i}>
        <CustomMessages  message={JSON.stringify(message)} />
      </Message>))
    );
  }
  return null;
};

export default BasicErrorMessage;
