import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  LOAD_EMPLOYEES,
  UPDATE_EMPLOYEE_PAGE_NUMBER,
  SG_GET_EMPLOYE_CATEGORY_LIST,
  UPDATE_EMPLOYEE_QUERY_PARAMS,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Icon,
} from "semantic-ui-react";

import OrganizationSelect from "organizations/OrganizationSelect";
import EmployeeSummary from "./Summary";
import CategoryList from "./CategoryList";

const List = ({ hideOrgSelect, orgId }) => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(false);
  const [resetPageLoad, setResetPageLoad] = useState(false);

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [categorySearch, setCategorySearch] = useState(false);
  const [categorySearchId, setCategorySearchId] = useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [queryParams, setQueryParams] = useState([]);

  const { get_employees, get_selectedOrg, get_organization_select } =
    useSelector(
      (state) => ({
        get_employees: state.employees,
        get_selectedOrg: state.selectedOrg,
        get_organization_select: state.organizations.organization_select,
      }),
      shallowEqual
    );

  const [searchTerm, setSearchTerm] = useState("");

  function applyFilters(
    catId = null,
    pageNo = false,
    orgId = false,
    searchDet = false
  ) {
    // setTimeout(() => {
    // if the response is ok 200
    let _queryParams = [];
    // Check if report status filter is present
    if (pageNo) {
      setPage(pageNo);
      _queryParams.push(`page=${pageNo}`);
    }
    if (orgId) {
      _queryParams.push(`organization=${orgId}`);
    }
    // Check if report status filter is present
    if (searchDet) {
      _queryParams.push(`search=${searchDet}`);
    }
    // Check if organization filter is present
    if (catId) {
      _queryParams.push(`category=${catId}`);
    }

    dispatch({
      type: UPDATE_EMPLOYEE_QUERY_PARAMS,
      payload: _queryParams,
    });
    // }, 500);
  }

  useEffect(() => {
    if (queryParams?.length > 0) {
      const parameters = {};

      queryParams.forEach((item) => {
        const [name, value] = item.split("=");
        parameters[name] = value;
      });
      if (parameters?.["page"]) {
        setPage(parameters["page"]);
      }
      if (parameters?.["category"]) {
        setCategorySearchId(parameters["category"]);
        setCategorySearch({
          id: parameters["category"],
          name: get_employees?.employee_category_list?.response
            ?.value_to_uid_list?.[parameters["category"]],
        });
      }
      if (parameters?.["organization"]) {
        setOrganizationId(parameters["organization"]);
      }
      // Check if organization filter is present
      if (parameters?.["search"]) {
        setSearchTerm(parameters["search"]);
      }
    }
  }, [
    queryParams,
    get_employees?.employee_category_list?.response?.value_to_uid_list,
  ]);

  useEffect(() => {
    if (queryParams && queryParams?.length > 0) {
      dispatch({
        type: LOAD_EMPLOYEES,
        payload: queryParams.join("&"),
      });

      dispatch({
        type: SG_GET_EMPLOYE_CATEGORY_LIST,
        payload: `show=true&${queryParams.join("&")}`,
      });
    } else {
      dispatch({
        type: LOAD_EMPLOYEES,
      });
      dispatch({
        type: SG_GET_EMPLOYE_CATEGORY_LIST,
        payload: `show=true`,
      });
    }
  }, [dispatch, queryParams]);

  useEffect(() => {}, [dispatch, queryParams]);

  useEffect(() => {
    if (get_employees?.queryParams) {
      setQueryParams(get_employees?.queryParams);
    }
  }, [get_employees]);

  function changeCategoryId(catId) {
    setCategorySearchId(catId);
    applyFilters(catId, 1, organizationId, searchTerm);
  }

  function changeOrganizationId(orgId) {
    setOrganizationId(orgId);
    applyFilters(categorySearchId, 1, orgId, searchTerm);
  }

  function changeSearchTerm(searchTerm) {
    setSearchTerm(searchTerm);
    applyFilters(categorySearchId, 1, organizationId, searchTerm);
  }

  useEffect(() => {
    setResults(get_employees?.employees?.results);
    setPage(get_employees?.pageNumber);
    setTotalPages(
      Math.floor(
        get_employees?.employees?.count / get_employees?.employees?.page_size
      )
    );
    if (get_employees?.employees?.count % get_employees?.employees?.page_size) {
      setTotalPages(
        Math.floor(
          get_employees?.employees?.count / get_employees?.employees?.page_size
        )
      );
    }
    setCategoryNames(get_employees?.employee_category_list?.response?.name);
    setCategoryValues(get_employees?.employee_category_list?.response?.value);
  }, [get_employees, page]);

  function handlePageChange(event, value) {
    applyFilters(
      categorySearchId,
      value.activePage,
      organizationId,
      searchTerm
    );

    setPage(value.activePage);
    dispatch({
      type: UPDATE_EMPLOYEE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_employees?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_employees]);

  function ResetCategoryList() {
    // setShowCategoryList(!showCategoryList);
    applyFilters(false, 1, organizationId, searchTerm);
    setCategorySearchId(false);
    setCategorySearch(false);
  }

  function ShowingCategoryList() {
    setShowCategoryList(!showCategoryList);
  }

  return (
    <Container>
      <p>Total Employees [{get_employees?.employees?.count}]</p>
      <SectionDiv>
        <SearchBar
          searchTerm={searchTerm}
          changeSearchTerm={changeSearchTerm}
        />
      </SectionDiv>
      {!hideOrgSelect && (
        <OrganizationSelect
          setResetPageLoad={setResetPageLoad}
          changeOrganizationId={changeOrganizationId}
        />
      )}

      {get_selectedOrg?.organization?.id && (
        <EmployeeSummary get_employees={get_employees} />
      )}

      <RightAlign>
        {categorySearchId &&
          (categorySearch?.name ? (
            <ContainerFieldSelectable onClick={ResetCategoryList}>
              Reset Category {categorySearch?.name} <Icon name="close" />
            </ContainerFieldSelectable>
          ) : (
            <ContainerFieldSelectable onClick={ResetCategoryList} warn={true}>
              <WarningDiv>
                Category Filter ON <Icon name="close" />
              </WarningDiv>
            </ContainerFieldSelectable>
          ))}
        <ContainerFieldSelectable onClick={ShowingCategoryList}>
          {showCategoryList ? (
            <>
              Hide Category List <Icon name="close" />
            </>
          ) : (
            "Show Category List"
          )}
        </ContainerFieldSelectable>
      </RightAlign>

      {showCategoryList && (
        <Container>
          <CategoryList
            names={categoryNames}
            values={categoryValues}
            changeCategoryId={changeCategoryId}
            setCategorySearch={setCategorySearch}
            valueIdName={
              get_employees?.employee_category_list?.response?.value_to_uid_list
            }
            nameIdName={
              get_employees?.employee_category_list?.response?.name_to_uid_list
            }
          />
        </Container>
      )}

      <Segment id="results" basic>
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results.map((result) => <RowCard row={result} key={result?.id} />)}
        </Card.Group>
        <SectionDiv>
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={page || 1}
            totalPages={totalPages || 1}
            onPageChange={handlePageChange}
          />
        </SectionDiv>
      </Segment>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerFieldSelectable = styled.span`
  padding: 5px;
  margin: 5px 10px;
  background-color: ${(props) => (props.warn ? "black" : "#dcdcdc")};
  cursor: pointer;
`;

const WarningDiv = styled.span`
  color: red;
  font-weight: 800;
`;
