import React, { useState, useEffect } from "react";
import styled from "styled-components";

// import Page from "components/Page";
// import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_LOGIN_HISTORY } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment, Header } from 'semantic-ui-react'

const List = ({ userId }) => {

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        // if the response is ok 200
        if (userId) {
            dispatch({
                type: SG_GET_LOGIN_HISTORY,
                payload: `user_id=${userId}`,
            });
        }
        setLoading(true)
    }, [dispatch, userId]);

    const { get_login_history } = useSelector(
        (state) => ({
            get_login_history: state.login_history,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_login_history?.login_history);

    }, [get_login_history]);




    useEffect(() => {
        // 
        const timer1 = setTimeout(() => {
            setLoading(get_login_history?.pending)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [get_login_history?.pending]);

    return (

        <Container>
          <Header as='h3'>Login History</Header>
                {/* <SectionDiv>
                    <SearchBar
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm} />

                    
                </SectionDiv> */}
            


            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results?.length > 0 ? results.map((result, i) => (
                        <RowCard row={result} key={i} />
                    )):
                    <p>No Login History found</p>}
                </Card.Group>
            </Segment>
           


        </Container>

    );
};

export default List;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`