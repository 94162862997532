import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Form, Button } from 'semantic-ui-react';


const SearchBar = ({searchTerm, changeSearchTerm }) => {
    const [searchBox, setSearchBox] = useState(searchTerm)
    function OnButtonClick() {
        changeSearchTerm(searchBox)
    }
    useEffect(() => {
        setSearchBox(searchTerm)
    },[searchTerm])
      return (
          <StyledForm success>
              <StyledInput 
                  label='Search'
                  value={searchBox}
                  onChange={(e) => setSearchBox(e.target.value)}
                  placeholder='Search...'
              />
              <StyledButton 
                  type='submit'
                  onClick={OnButtonClick}
              >
                  Search
              </StyledButton>
          </StyledForm>
      );
  };
  
  
  SearchBar.propTypes = {
      className: PropTypes.string
  };
  
  export default SearchBar;
  
  
  // Styled components
  const StyledForm = styled(Form)`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      background-color: #f9f9f9;
  `;
  
  const StyledInput = styled(Form.Input)`
      flex: 1;
      margin-right: 10px;
  `;
  
  const StyledButton = styled(Button)`
      background-color: #0079d3;
      color: white;
      &:hover {
          background-color: #005fa3;
      }`