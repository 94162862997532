import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_DEBRIEF_RESPONSES, DEBRIEF_RESPONSE_PAGE_NUMBER } from "constants/actions";

import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react'

const BriefResponseList = ({ deBriefId, employeeId, empUpdateRow,  setEmpUpdateRow, searchTerm}) => {
    const dispatch = useDispatch();

    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [reloadPAge, setReloadPage] = useState(false)

    useEffect(() => {
        // if the response is ok 200
        // console.log("page changed to ", page)
        if (deBriefId) {
            dispatch({
                type: SG_GET_DEBRIEF_RESPONSES,
                payload: `page=${page}${deBriefId ? `&debrief_schedule=${deBriefId}` : ''}${searchTerm? `&survey_token=${searchTerm}` : ''}`,
            });
            
        }
        if (employeeId) {
            dispatch({
                type: SG_GET_DEBRIEF_RESPONSES,
                payload: `page=${page}${employeeId ? `&employee=${employeeId}` : ''}`,
            });
            
        }
        setLoading(true)
    }, [dispatch, page, deBriefId, employeeId, empUpdateRow, searchTerm, reloadPAge]);

    const { get_debrief_responses } = useSelector(
        (state) => ({
            get_debrief_responses: state.debrief_response,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_debrief_responses?.debrief_response?.results);
        setPage(get_debrief_responses?.pageNumber)
        setTotalPages(Math.ceil(get_debrief_responses?.debrief_response?.count / get_debrief_responses?.debrief_response?.page_size))
        if (get_debrief_responses?.debrief_response?.count % get_debrief_responses?.debrief_response?.page_size) {
            setTotalPages(Math.ceil(get_debrief_responses?.debrief_response?.count / get_debrief_responses?.debrief_response?.page_size))
        }
    }, [get_debrief_responses, page]);


    function handlePageChange(_event, value) {
        setPage(value.activePage);
        dispatch({
            type: DEBRIEF_RESPONSE_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    // console.log(get_debrief_responses?.debrief_response?.count)
    // console.log(get_debrief_responses?.debrief_response?.page_size)
    // console.log((get_debrief_responses?.debrief_response?.count / get_debrief_responses?.debrief_response?.page_size))
    // console.log(totalPages)
    return (

        <Container>
            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Card.Group>
                    {results && results.map((result) => (
                        <RowCard 
                        row={result} key={result?.id} 
                        empUpdateRow={true} 
                        setEmpUpdateRow={setEmpUpdateRow}
                        setReloadPage={setReloadPage}
                        
                         />
                    ))}
                </Card.Group>
            </Segment>
            <SectionDiv>

                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page|| 1}
                    totalPages={totalPages || 1}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>

        </Container>

    );
};

export default BriefResponseList;


const Container = styled.div`
//   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`