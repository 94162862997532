import React, { useState, useEffect } from "react";
import styled from "styled-components";


import Select from "react-select";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message } from 'semantic-ui-react'

import {
    SG_GET_ORGANIZATION_SELECT,
    PULL_ORGANIZATION,
    GET_AM_ORGANIZATION,
} from "constants/actions";

const QUERY_PARAMS = "organization"

const OrganizationSelect = ({setResetPageLoad, changeOrganizationId=false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    let organization_id = searchParams.get(QUERY_PARAMS);
   
    const newQueryParameters = new URLSearchParams();

    const [organizationId, setOrganizationId] = useState(false)
    const [organizationSelect, setOrganizationSelect] = useState([])
    const [organizationSelected, setOrganizationSelected] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null);

    const { get_selectedOrg,
        get_organization_select, } = useSelector(
            (state) => ({
                get_organization_select: state.organizations.organization_select,
                get_selectedOrg: state.selectedOrg,
            }),
            shallowEqual
        );

    useEffect(() => {
        dispatch({
            type: SG_GET_ORGANIZATION_SELECT
        })
        if (organizationId > 0) {
            dispatch({
                type: PULL_ORGANIZATION,
                payload: { id: organizationId }
            });
        }

    }, [dispatch, organizationId]);

    useEffect(() => {
      if (organization_id) {
        setOrganizationId(organization_id)
      } else {
        setOrganizationId(get_selectedOrg?.organization?.id)
      }
      setOrganizationDetails(get_selectedOrg?.organization)
    }, [organization_id, get_selectedOrg]);



    useEffect(() => {
        setOrganizationSelect(get_organization_select)
        if (organizationId !== organizationSelected) {
            setOrganizationSelected(get_organization_select.filter(
                org => org.id === Number(organizationId))?.[0])
        }
    }, [get_organization_select, organizationId, organizationSelected]);

    useEffect(() => {
        setOrganizationDetails(get_selectedOrg?.organization)
    }, [get_selectedOrg]);

    function ResetOrganization() {
      dispatch({
          type: GET_AM_ORGANIZATION,
          payload: null
      });
      if (changeOrganizationId) {
        changeOrganizationId(null)
      }
      navigate(`${window.location.pathname}`)
      setResetPageLoad(Math.round((new Date()).getTime() / 1000))
    }


    function ChangedOrganization(e) {
        dispatch({
            type: GET_AM_ORGANIZATION,
            payload: e
        });
        newQueryParameters.set(QUERY_PARAMS, e.id)
        setSearchParams(newQueryParameters)
        setResetPageLoad(Math.round((new Date()).getTime() / 1000))
        if (changeOrganizationId) {
          changeOrganizationId(e.id)
        }
       
    }

    return (
        <Container>
            <SectionDiv>
                {organizationDetails?.id?
                <Message
                onDismiss={ResetOrganization}
                header={`${organizationDetails?.id} ${organizationDetails?.name} `}
                content='Close this to reset the organization or select another one.'
              /> :
                <Select
                    style={{ zIndex: 999 }}
                    name="survey_preference"
                    options={organizationSelect}
                    onChange={ChangedOrganization}
                    getOptionLabel={(option) => `${option.id} # ${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    value={organizationSelected}
                />
                }
                
            </SectionDiv>

        </Container>
    );
};

export default OrganizationSelect;

const Container = styled.div`
  margin-bottom: 30px;
  z-index: 999;
`;

const CenteredObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`

const CenterContent = styled.div`
display: flex;
  align-items: center;
  justify-content: center;

`

const Table = styled.div`
  width: 100%;
  margin-top: 30px;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;


